import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../../../store/language/actions";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate } from "react-router-dom";
import { AddCharacter, AddTag, uploadFile } from "../../../../store/actions";
const AddTags = () => {
  const { loading } = useSelector((state) => state.Subscription);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    gender:"",
    titles: [
      {
        language: "",
        title: "",
      },
    ],
  });
  const fetchLanguages = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error(err);
        } else {
          setLanguage(res);
        }
      };
      dispatch(getAllLanguage({ callback }));
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    fetchLanguages();
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleTitleTypeChange = (index, field, value) => {
    const updatedTitles = [...formData.titles];
    updatedTitles[index] = {
      ...updatedTitles[index],
      [field]: value,
    };
    setFormData({ ...formData, titles: updatedTitles });
  };
  const addTitleType = () => {
    setFormData({
      ...formData,
      titles: [...formData.titles, { language: "", title: "" }],
    });
  };

  const removeTitleType = (index) => {
    const updatedTitles = formData.titles.filter((_, i) => i !== index);
    setFormData({ ...formData, titles: updatedTitles });
  };

  const handleFiles = (_files) => {
    const files = Array.from(_files).filter((file) => file.size < 10e6);
    if (files.length < _files.length) {
      toast.error("Max file upload size is 10MB");
      return;
    }
    const callback = (err, res) => {
      if (err) {
        console.error("File upload error:", err);
        toast.error("Failed to upload file");
      } else {
        console.log(res, " response with  url");
        toast.success("File uploaded successfully");
        setFormData((prev) => ({
          ...prev,
          image: res?.data?.url,
        }));
      }
    };
    dispatch(uploadFile({ data: files[0], callback }));
    setFormData((prev) => ({
      ...prev,
      image: URL.createObjectURL(files[0]),
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "forndata =======<>");
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error("Error submitting:", err);
        } else {
          toast.success(res.message);
          navigate("/tags");
          setFormData({
           name:"",
           type:"",
           gender:"",
            titles: [
              {
                language: "",
                title: "",
              },
            ],
          });
        }
      };
      dispatch(AddTag({ data: formData, callback }));
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form className="mx-auto" onSubmit={handleSubmit}>
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Add Tag:
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="ModalType"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Tag type
                          </label>
                          <select
                            className="form-control"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                          >
                            <option value="">Select tag type</option>
                            <option value="tag">Tag</option>
                            <option value="personality">Personality</option>
                            <option value="hobby">Hobby</option>
                            <option value="cloths">cloths</option>
                            <option value="background">Background</option>
                            <option value="occupation">Occupation</option>
                          </select>
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="ModalType"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Gender
                          </label>
                          <select
                            className="form-control"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="all">All</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </Col>
                        <Col md="12" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Col>
                        {formData.titles.map((item, index) => (
                          <React.Fragment key={index}>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`language-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Select Language
                              </label>
                              <select
                                className="form-control"
                                value={item.language}
                                onChange={(e) =>
                                  handleTitleTypeChange(
                                    index,
                                    "language",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Language</option>
                                {language.map((lang) => (
                                  <option key={lang._id} value={lang.code}>
                                    {lang.name}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`title-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Title
                              </label>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control me-2"
                                  value={item.title}
                                  onChange={(e) =>
                                    handleTitleTypeChange(
                                      index,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                />
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => removeTitleType(index)}
                                  disabled={formData.titles.length === 1}
                                >
                                  Remove
                                </Button>
                              </div>
                            </Col>
                          </React.Fragment>
                        ))}

                        <div className="d-flex px-4">
                          <Button
                            className="mt-2 btn-sm"
                            onClick={addTitleType}
                            type="button"
                          >
                            Add More
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>

                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn w-100 fw-sbold"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AddTags;
