import { createAsyncThunk } from "@reduxjs/toolkit"
import { post, get, put, del } from "../../helpers/api_helper"
import slice from "./slice"
import * as URL from "../../helpers/url_helper"
import { toast } from "react-toastify"

export const getAppearnace = createAsyncThunk(
    "getAppearnace",
    async ({ data, callback }, Thunk) => {
      try {
        let response = await get(URL.GET_APPEARANCE);
        callback && callback(null, response);
        return response;
      } catch (error) {
        console.log(error,"error")
        return Thunk.rejectWithValue(error);
      }
    }
  );
    export const editFaqCategoryPosition = createAsyncThunk(
      "editFaqCategory",
      async ({ data, callback }, Thunk) => {
        try {
          let response = await put(URL.FAQ_CATEGORY_POSITION + data?._id, data);
          callback && callback(null, response);
          return response;
        } catch (error) {
          callback && callback(error.message, null);
          return Thunk.rejectWithValue(error);
        }
      }
    );