const inputFields = [
    {
      name: "language",
      type: "dynamic_select",
      label: "Select Language",
      placeholder: "",
      options: [],
    },

    {
      name: "name",
      type: "text ",
      label: "Category Name",
      placeholder: "",
    },
 
 
  ];
  
  export default inputFields;
  