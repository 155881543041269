import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import slice from "./slice";
import * as URL from "../../helpers/url_helper";
import { toast } from "react-toastify";

export const characterList = createAsyncThunk(
  "characterList",
  async ({ data, callback }, Thunk) => {
    try {
    let response = await post(URL.CHARACTER_LIST, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);
export const deleteCharacterList = createAsyncThunk(
  "deleteCharacterList",
  async ({ data, callback }, thunkAPI) => {
    console.log(data, "data++=++++++++++++++++++++");
    try {
      const response = await del(URL.DELETE_CHARACTER_LIST + data);
      console.log(data, "data----------->");
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      callback && callback(error.message, null);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCharacterDetails = createAsyncThunk(
  "getCharacterDetails",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.VIEW_CHARACTER + data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
