import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";
const initialState = {
  loading: false,
  TAG: [],
  totalCount: 0,
};
const slice = createSlice({
  name: "TAG",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getTag.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getTag.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      TAG: payload || [],
      totalCount: payload.totalcount || 0,
    }));
    builder.addCase(Act.getTag.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload || "Unknown error",
      TAG: [],
      totalCount: 0,
    }));
    builder.addCase(Act.viewTag.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.viewTag.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      TAG: payload || [],
      totalCount: payload.totalcount || 0,
    }));
    builder.addCase(Act.viewTag.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload || "Unknown error",
      TAG: [],
      totalCount: 0,
    }));
    builder.addCase(Act.AddTag.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(
      Act.AddTag.fulfilled,
      (state, { payload }) => (
        console.log(payload),
        {
          ...state,
          loading: false,
        }
      )
    );
    builder.addCase(Act.AddTag.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // edit token package
    builder.addCase(Act.editTag.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(
      Act.editTag.fulfilled,
      (state, { payload }) => (
        console.log(payload),
        {
          ...state,
          loading: false,
        }
      )
    );
    builder.addCase(Act.editTag.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // delete token package
    builder.addCase(Act.deleteTag.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Act.deleteTag.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Act.deleteTag.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });
  },
});

export default slice;
