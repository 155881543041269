import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import slice from "./slice";
import * as URL from "../../helpers/url_helper";
import { toast } from "react-toastify";

export const getHome = createAsyncThunk(
  "getHome",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.HOME_CONTENT, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);

export const AddContent = createAsyncThunk(
  "AddContent",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.ADD_HOME, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const editHome = createAsyncThunk(
  "editHome",
  async ({ data, callback }, Thunk) => {
    try {
      let payload = JSON.parse(JSON.stringify(data));
      delete payload.id;
      let response = await put(URL.EDIT_CONTENT + data?.id, payload);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const viewHome = createAsyncThunk(
  "viewHome",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.VIEW_HOME + data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteHome = createAsyncThunk(
  "deleteHome",
  async ({ data, callback }, thunkAPI) => {
    console.log(data, "data++=++++++++++++++++++++");
    try {
      const response = await del(URL.DELETE_CONTENT + data._id);
      console.log(data, "data----------->");
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      callback && callback(error.message, null);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
