export const LOGIN_USER = "/admin/login";
export const FORGET_PASSWORD = "/admin/forgotPassword";
export const UPDATE_ADMIN = "/admin/edit";
export const VIEW_USER = "/admin/view";
export const RESET_PASSWORD = "/admin/changePassword/";
export const CHANGE_PASSWORD="/admin/change_password"
// File
export const POST_FILE = "/admin/upload";
export const POST_REMOVE_FILE = "/settings/remove";
//pages
export const CONTENTPAGES="/admin/pages"
export const ADD_PAGES="/admin/pages"
export const DELETE_PAGES="/admin/pages/"
export const EDIT_PAGE="/admin/pages/"
// users
export const USER_LIST="/admin/user"
export const UPDATE_USER="/admin/user/update_status/"
export const USER_VIEW="/admin/user/"
export const USER_PRIVATE_CHARACTER="/admin/user/characters/"
export const USER_SUBSCRIPTION="/admin/user/subscriptions/"
export const USER_TOKEN="/admin/user/tokens/"
// profile     views 
export const PROFILE_VIEWS="/admin/profile"
// faq routes
export const FAQ_LIST="/admin/faq"
export const ADD_FAQ="/admin/faq"
export const UPDATE_FAQ="/admin/faq/"
export const VIEWS_FAQ="/admin/faq/"
export const DELETE_FAQ="/admin/faq/"
export const GET_CATEGORY_WITHLANG="/admin/faq/category/"
//faqcategory
export const FAQ_CATEGORY_LIST="/admin/faq_category"
export const FAQ_CATEGORY_ADD="/admin/faq_category"
export const FAQ_CATEGORY_EDIT="/admin/faq_category/"
export const FAQ_CATEGORY_DELETE="/admin/faq_category/"
export const FAQ_CATEGORY_POSITION="/admin/faq_category/change_position/"
// settings 
export const SETTING_VIEW="/admin/setting"
export const EDIT_SETTING="/admin/setting/"
// get appearance 
export const GET_APPEARANCE="/admin/character_appearances"
// token packages
export const TOKEN_PACKAGES="/admin/token_packages"
export const ADD_TOKEN_PACKAGE="/admin/token_packages"
export const EDIT_TOKEN_PACKAGE="/admin/token_packages/"
export const DELETE_TOKEN_PACKAGE="/admin/token_packages/"
// Charcter settings
export const CHARACTER_SETTING="/admin/character_setting"
export const ADD_CHARACTER="/admin/character_setting"
export const EDIT_CHARACTER="/admin/character_setting/"
export const DELETE_CHARACTER="/admin/character_setting/"

// dashboard data
export const GET_DASHBOARD_DATA="/admin/dashboard"
export const GET_REVENUE="/admin/dashboard/revenue"
export const GET_CHARACTER_REVENUE="/admin/dashboard/characters_graph"
// user revneue 
export const USER_REVENUE_LIST="/admin/user/revenue"
export const USER_TRANSACTION="/admin/revenue_trasactions"
export const USER_CHARACTER_REVENUE="/admin/character_revenue"
export const REVENUE_DOWNLOAD="/admin/user/revenue"
export const PAID_REVENUE_DOWNLOAD="/admin/revenue_transfer"
// character list  type wise
export const CHARACTER_LIST="/admin/characters/list"
export const DELETE_CHARACTER_LIST="/admin/characters/"
export const VIEW_CHARACTER="/admin/characters/"
// userlist download
export const USER_LIST_DOWNLOAD="/admin/user"
export const DOWNLOAD_TRANSACTION_LIST="/admin/payments"
// Langauge 
export const LANGUAGE_LIST="/admin/language"
export const ADD_LANGUAGE="/admin/language"
export const EDIT_LANGUAGE="/admin/language/"
export const DELETE_LANGUAGE="/admin/language/"
export const ALL_LANGUAGE ="/admin/all_languages"
// SUBSCRIPTION
export const SUBSCRIPTION_LIST = "/admin/plan";
export const SUBSCRIPTION_ADD = "/admin/plan";
export const SUBSCRIPTION_DETAIL = "/admin/plan";
export const EDIT_SUBSCRIPTION="/admin/plan/"
export const DELETE_SUBSCRIPTION="/admin/plan/"
// transaction manage 
export const TRANSACTION_LIST="/admin/payments"
// tags upadte 
export const TAGS_LIST="admin/tags/list"
export const ADD_TAGS="/admin/tags"
export const EDIT_TAGS="/admin/tags/"
export const VIEW_TAGS="/admin/tags/"
export const DELETE_TAGS="/admin/tags/"

export const HOME_CONTENT="/admin/contents/list"
export const ADD_HOME="/admin/contents"
export const EDIT_CONTENT="/admin/contents/"
export const DELETE_CONTENT="/admin/contents/"
export const VIEW_HOME="/admin/contents/"
