import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import slice from "./slice";
import * as URL from "../../helpers/url_helper";
import { toast } from "react-toastify";

export const RevenueList = createAsyncThunk(
  "RevenueList",
  async ({ data, callback }, Thunk) => {
    try {
    let response = await post(URL.USER_REVENUE_LIST, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);

export const DownloadUserRevenueList = createAsyncThunk(
  "DownloadUserRevenueList",
  async ({ data, callback }, Thunk) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const url = `${URL.REVENUE_DOWNLOAD}?${queryParams}`;
      let response = await get(url);
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);
export const UserTransaction = createAsyncThunk(
  "UserTransaction",
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await post(URL.USER_TRANSACTION ,data);
      console.log(data, "data----------->");
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      callback && callback(error.message, null);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const UserCharacterRevenue = createAsyncThunk(
  "UserCharacterRevenue",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.USER_CHARACTER_REVENUE , data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
