import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
const AreaChart = ({ revenue, filter }) => {
  const { data } = revenue || { data: {} };

  const [filteredData, setFilteredData] = useState([]);
  const [chartOptions, setChartOptions] = useState({
    series: [],
    options: {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        title: {
          text: "Time Period",
        },
      },
      yaxis: {
        title: {
          text: "Revenue",
        },
        labels: {
          formatter: (value) => `$${value.toLocaleString()}`,
        },
      },
      tooltip: {
        x: {
          formatter: (value) => value,
        },
        y: {
          formatter: (value) => `$${value.toLocaleString()}`,
        },
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  });

  const transformData = () => {
    if (filter?.filter === "yearly") {
      return Object.entries(data)
        .filter(([key]) => /^\d{4}$/.test(key))
        .map(([year, value]) => ({ x: year, y: value }));
    } else if (filter?.filter === "monthly") {
      return Object.entries(data)
        .filter(([key]) => (key))
        .map(([month, value]) => ({ x: month, y: value }));
    } else if (filter?.filter === "weekly") {
      return Object.entries(data)
        .filter(([key]) =>
          /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)$/.test(
            key
          )
        )
        .map(([day, value]) => ({ x: day, y: value }));
    }
    return [];
  };
  useEffect(() => {
    const newFilteredData = transformData();
    setFilteredData(newFilteredData);
  }, [filter, data]);
  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: [
        {
          name: "Revenue",
          data: filteredData,
        },
      ],
      options: {
        ...prevOptions.options,
        xaxis: {
          ...prevOptions.options.xaxis,
          title: {
            text:
              filter?.filter === "yearly"
                ? "YearLy"
                : filter?.filter === "monthly"
                ? "Monthly"
                : "Weekly",
          },
        },
      },
    }));
  }, [filteredData, filter]);
  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type="area"
          height={350}
        />
      </div>
    </div>
  );
};

export default AreaChart;
