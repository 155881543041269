const inputFields = [
  {
    name: "code",
    type: "dynamic_select",
    label: "Code",
    placeholder: "",
    options: [],
  },
  {
    name: "name",
    type: "text",
    label: "Language Name*",
    placeholder: "Enter language Name",
  },
];

export default inputFields;
