import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";
const initialState = {
  loading: false,
  DASHBOARDDATA: [],
  totalCount: 0,
};
const slice = createSlice({
  name: "DASHBOARDDATA",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getDashboardData.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getDashboardData.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      DASHBOARDDATA: payload || [],
      totalCount: payload.totalcount || 0,
    }));
    builder.addCase(Act.getDashboardData.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload || "Unknown error",
      DASHBOARDDATA: [],
      totalCount: 0,
    }));
    builder.addCase(Act.getRevenue.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(
      Act.getRevenue.fulfilled,
      (state, { payload }) => (
        console.log(payload),
        {
          ...state,
          loading: false,
        }
      )
    );
    builder.addCase(Act.getRevenue.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.getCharacterRevenue.pending, (start, action) => ({
        ...start,
        loading: true,
      }));
      builder.addCase(
        Act.getCharacterRevenue.fulfilled,
        (state, { payload }) => (
          console.log(payload),
          {
            ...state,
            loading: false,
          }
        )
      );
      builder.addCase(Act.getCharacterRevenue.rejected, (state, action) => ({
        ...state,
        loading: false,
      }));
  },
});

export default slice;
