import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import * as URL from "../../helpers/url_helper";
export const DownloadUserList = createAsyncThunk(
  "DownloadUserList",
  async ({ data, callback }, Thunk) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const url = `${URL.USER_LIST_DOWNLOAD}?${queryParams}`;
      let response = await get(url);
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);
export const DownloadRevenueList = createAsyncThunk(
  "DownloadRevenueList",
  async ({ data, callback }, Thunk) => {
    try {
    let response = await post(URL.USER_REVENUE_LIST, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);
export const DownloadPaidRevenueList = createAsyncThunk(
  "DownloadPaidRevenueList",
  async ({ data, callback }, Thunk) => {
    try {
    let response = await post(URL.PAID_REVENUE_DOWNLOAD, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);
export const DownloadTransactionList = createAsyncThunk(
  "DownloadTransactionList",
  async ({ data, callback }, Thunk) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const url = `${URL.DOWNLOAD_TRANSACTION_LIST}?${queryParams}`;
      let response = await get(url);
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);

