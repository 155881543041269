const inputFields = [
  {
    name: "language",
    type: "dynamic_select",
    label: "Select Language",
    placeholder: "",
    options: [],
  },
  {
    name: "category",
    type: "select",
    label: "Select Category Type",
    placeholder: "",
    options: [],
  },
  {
    name: "question",
    type: "text ",
    label: "Questions",
    placeholder: "",
  },
  {
    name: "status",
    type: "select",
    label: "Status",
    placeholder: "",
    options: [
      {
        value: true,
        label: "Active ",
      },
      {
        value: false,
        label: "InActive",
      },
    ],
  },
  {
    name: "answer",
    type: "ckeditor",
    label: "Answer",
    placeholder: "",
  },
];

export default inputFields;
