import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import logo from "../../Assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getUserPrivateCharacter } from "../../store/actions";
import { toast } from "react-toastify";
const PersonalCharacterView = ({ id }) => {
  const [data, setData] = useState();
  const [visibleItems, setVisibleItems] = useState(10)
  const { totalCount, loading } = useSelector((state) => state.User);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    id: id,
    limit: 10,
    pageNo: 1,
    type: "personal",
  });
  const getUserPrivate = () => {
    const callback = (err, res) => {
      if (err) {
        console.log(err);
      } else {
        toast.success(res);
        setData(res);
      }
    };
    dispatch(getUserPrivateCharacter({ data: filter, callback }));
  };
  const loadMore = () => {
    setFilter((prev) => ({
      ...prev,
      limit: prev.limit + 10,
    }));
  };  
  useEffect(() => {
    getUserPrivate();
  }, [filter]);
  console.log(data, "data personal");
  return (
    <>
      <div className="d-flex gap-2 flex-wrap">
        <Row className="w-100">
          {data?.length > 0 ? (
            data?.map((item, indx) => {
              return (
                <>
                  <Col md="4" sm="6" className="my-2">
                    <div className="cardCstm p-3 border rounded-4 bg-light shadow-sm h-100 position-relative">
                      <div className="top pt-2 pb-3 border-bottom border-secondary  ">
                        <div className="left">
                          <h5 className="m-0 fw-bold themeClr">
                            {item?.name}
                            <span
                              className="text-muted fw-sbold text-capitalize"
                              style={{ fontSize: 12 }}
                            >
                              ({item?.type})
                            </span>
                          </h5>
                          <div className="price mt-3">
                            <div className="imgWrp flex-shrink-0 w-100  ">
                              {item?.image ? (
                                <img
                                  src={item?.image}
                                  alt=""
                                  style={{ height: 200 }}
                                  className="img-fluid object-fit-cover w-100 rounded"
                                />
                              ) : (
                                <img
                                  src={logo}
                                  alt=""
                                  // style={{ height: 40, width: 40 }}
                                  className="img-fluid object-fit-cover rounded"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="content">
                        <div className="price mt-3 d-flex justify-content-between">
                          <h6 className="m-0 fw-bold"> Age: {item?.age}</h6>
                          <span
                            className="fw-sbold justify-content-end"
                            style={{ fontSize: 12 }}
                          >
                            {item?.gender}
                          </span>
                        </div>
                        <ul
                          className="list-unstyled ps-0 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <li>
                            <span className="d-flex justify-content-between px-2 mb-0">
                              <p className="fw-medium m-0">Accent</p>
                              <p className="m-0"> {item?.accent}</p>
                            </span>
                          </li>
                          <li>
                            <span className="d-flex justify-content-between px-2 mb-0">
                              <p className="fw-medium m-0">Style</p>
                              <p className="m-0"> {item?.style}</p>
                            </span>
                          </li>
                          <li>
                            <span className="d-flex justify-content-between px-2 mb-0">
                              <p className="fw-medium m-0">Breast</p>
                              <p className="m-0"> {item?.breast}</p>
                            </span>
                          </li>
                          <li>
                            <span className="d-flex justify-content-between px-2 mb-0">
                              <p className="fw-medium m-0">Butt</p>
                              <p className="m-0"> {item?.butt}</p>
                            </span>
                          </li>
                          <li>
                            <span className="d-flex justify-content-between px-2 mb-0">
                              <p className="fw-medium m-0">Ethnicity</p>
                              <p className="m-0"> {item?.ethnicity}</p>
                            </span>
                          </li>
                          <li className="d-flex">
                            <span className="px-2 m-0">
                              <p className="fw-medium m-0">Introduction</p>
                              <p className="m-0"> {item?.introduction}</p>
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </>
              );
            })
          ) : (
            <div className="d-flex justify-content-center p-2">
              <p className="m-0">No Data Found</p>
            </div>
          )}
        </Row>
        {data?.length &&
        (
          <Button onClick={loadMore} className="mt-3">
            Load More
          </Button>
        )}
      </div>
    </>
  );
};

export default PersonalCharacterView;
