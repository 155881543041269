import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import slice from "./slice";
import * as URL from "../../helpers/url_helper";
import { toast } from "react-toastify";

export const getTag = createAsyncThunk(
  "getTag",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.TAGS_LIST, data);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);

export const AddTag = createAsyncThunk(
  "AddTag",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.ADD_TAGS, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const editTag = createAsyncThunk(
  "editTag",
  async ({ data, callback }, Thunk) => {
    try {
      let payload = JSON.parse(JSON.stringify(data));
      delete payload.id;
      let response = await put(URL.EDIT_TAGS + data?.id, payload);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const viewTag = createAsyncThunk(
  "viewTag",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.VIEW_TAGS + data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteTag = createAsyncThunk(
  "deleteTag",
  async ({ data, callback }, thunkAPI) => {
    console.log(data, "data++=++++++++++++++++++++");
    try {
      const response = await del(URL.DELETE_TAGS + data);
      console.log(data, "data----------->");
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
      callback && callback(error.message, null);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
