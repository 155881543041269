import React from "react";
import { Col, Row } from "react-bootstrap";
// css
import styles from "./Featured.module.scss";

const FeatureCard = ({dashBoardData}) => {
  const {personalCount,publicCount,subscriptionRevenue,tokenRevenue,users}=dashBoardData
  console.log(personalCount,publicCount,subscriptionRevenue,tokenRevenue,users,"data======<>")
  const data = [
    {
      icn: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 54 54"
          fill="none"
        >
          <circle cx="27" cy="27" r="27" fill="white" />
          <path
            d="M22.5 17C21.5729 17.0037 20.6848 17.3736 20.0292 18.0292C19.3736 18.6848 19.0037 19.5729 19 20.5C19 22.422 20.578 24 22.5 24C24.422 24 26 22.422 26 20.5C26 18.578 24.422 17 22.5 17ZM31.5 17C30.5729 17.0037 29.6848 17.3736 29.0292 18.0292C28.3736 18.6848 28.0037 19.5729 28 20.5C28 22.422 29.578 24 31.5 24C33.422 24 35 22.422 35 20.5C35 18.578 33.422 17 31.5 17ZM22.5 19C23.34 19 24 19.66 24 20.5C24 21.34 23.34 22 22.5 22C21.66 22 21 21.34 21 20.5C21 19.66 21.66 19 22.5 19ZM31.5 19C32.34 19 33 19.66 33 20.5C33 21.34 32.34 22 31.5 22C30.66 22 30 21.34 30 20.5C30 19.66 30.66 19 31.5 19ZM18 23C15.8 23 14 24.8 14 27C14 28.113 14.477 29.117 15.219 29.844C14.5383 30.3033 13.9803 30.9221 13.5935 31.6465C13.2068 32.3709 13.003 33.1788 13 34H15C15 32.332 16.332 31 18 31C19.668 31 21 32.332 21 34H23C22.997 33.1788 22.7932 32.3709 22.4065 31.6465C22.0197 30.9221 21.4617 30.3033 20.781 29.844C21.523 29.117 22 28.114 22 27C22 24.8 20.2 23 18 23ZM23 34C22.375 34.836 22 35.887 22 37H24C24 35.332 25.332 34 27 34C28.668 34 30 35.332 30 37H32C31.9983 35.9184 31.6476 34.8663 31 34C30.66 33.547 30.25 33.16 29.781 32.844C30.523 32.117 31 31.114 31 30C31 27.8 29.2 26 27 26C24.8 26 23 27.8 23 30C23 31.113 23.477 32.117 24.219 32.844C23.7509 33.1585 23.3388 33.5492 23 34ZM31 34H33C33 32.332 34.332 31 36 31C37.668 31 39 32.332 39 34H41C40.997 33.1788 40.7932 32.3709 40.4065 31.6465C40.0197 30.9221 39.4617 30.3033 38.781 29.844C39.523 29.117 40 28.114 40 27C40 24.8 38.2 23 36 23C33.8 23 32 24.8 32 27C32 28.113 32.477 29.117 33.219 29.844C32.5383 30.3033 31.9803 30.9221 31.5935 31.6465C31.2068 32.3709 31.003 33.1788 31 34ZM18 25C19.117 25 20 25.883 20 27C20 28.117 19.117 29 18 29C16.883 29 16 28.117 16 27C16 25.883 16.883 25 18 25ZM36 25C37.117 25 38 25.883 38 27C38 28.117 37.117 29 36 29C34.883 29 34 28.117 34 27C34 25.883 34.883 25 36 25ZM27 28C28.117 28 29 28.883 29 30C29 31.117 28.117 32 27 32C25.883 32 25 31.117 25 30C25 28.883 25.883 28 27 28Z"
            fill="#4A4A4A"
          />
        </svg>
      ),
      label: "Number of Users",
      value: users,
    },
    {
      icn: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 54 54"
          fill="none"
        >
          <circle cx="27" cy="27" r="27" fill="white" />
          <path
            d="M32.75 22.4585C32.6875 22.4481 32.6146 22.4481 32.5521 22.4585C31.1146 22.4064 29.9688 21.2293 29.9688 19.771C29.9688 18.2814 31.1667 17.0835 32.6562 17.0835C34.1458 17.0835 35.3437 18.2918 35.3437 19.771C35.3333 21.2293 34.1875 22.4064 32.75 22.4585Z"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.677 30.042C33.1041 30.2816 34.677 30.0316 35.7812 29.292C37.2499 28.3128 37.2499 26.7087 35.7812 25.7295C34.6666 24.9899 33.0728 24.7399 31.6458 24.9899"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.2188 22.4585C20.2813 22.4481 20.3542 22.4481 20.4167 22.4585C21.8542 22.4064 23 21.2293 23 19.771C23 18.2814 21.8021 17.0835 20.3125 17.0835C18.8229 17.0835 17.625 18.2918 17.625 19.771C17.6354 21.2293 18.7813 22.4064 20.2188 22.4585Z"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.2917 30.042C19.8646 30.2816 18.2917 30.0316 17.1875 29.292C15.7188 28.3128 15.7188 26.7087 17.1875 25.7295C18.3021 24.9899 19.8958 24.7399 21.3229 24.9899"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.5 30.2398C26.4375 30.2293 26.3646 30.2293 26.3021 30.2398C24.8646 30.1877 23.7188 29.0106 23.7188 27.5522C23.7188 26.0627 24.9167 24.8647 26.4062 24.8647C27.8958 24.8647 29.0938 26.0731 29.0938 27.5522C29.0833 29.0106 27.9375 30.1981 26.5 30.2398Z"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23.4688 33.521C22 34.5002 22 36.1043 23.4688 37.0835C25.1354 38.1981 27.8646 38.1981 29.5313 37.0835C31 36.1043 31 34.5002 29.5313 33.521C27.875 32.4168 25.1354 32.4168 23.4688 33.521Z"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      label: "Public Character",
      value: publicCount,
    },
    {
      icn: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 54 54"
          fill="none"
        >
          <circle cx="27" cy="27" r="27" fill="white" />
          <path
            d="M32.75 22.4585C32.6875 22.4481 32.6146 22.4481 32.5521 22.4585C31.1146 22.4064 29.9688 21.2293 29.9688 19.771C29.9688 18.2814 31.1667 17.0835 32.6562 17.0835C34.1458 17.0835 35.3437 18.2918 35.3437 19.771C35.3333 21.2293 34.1875 22.4064 32.75 22.4585Z"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M31.677 30.042C33.1041 30.2816 34.677 30.0316 35.7812 29.292C37.2499 28.3128 37.2499 26.7087 35.7812 25.7295C34.6666 24.9899 33.0728 24.7399 31.6458 24.9899"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.2188 22.4585C20.2813 22.4481 20.3542 22.4481 20.4167 22.4585C21.8542 22.4064 23 21.2293 23 19.771C23 18.2814 21.8021 17.0835 20.3125 17.0835C18.8229 17.0835 17.625 18.2918 17.625 19.771C17.6354 21.2293 18.7813 22.4064 20.2188 22.4585Z"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21.2917 30.042C19.8646 30.2816 18.2917 30.0316 17.1875 29.292C15.7188 28.3128 15.7188 26.7087 17.1875 25.7295C18.3021 24.9899 19.8958 24.7399 21.3229 24.9899"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M26.5 30.2398C26.4375 30.2293 26.3646 30.2293 26.3021 30.2398C24.8646 30.1877 23.7188 29.0106 23.7188 27.5522C23.7188 26.0627 24.9167 24.8647 26.4062 24.8647C27.8958 24.8647 29.0938 26.0731 29.0938 27.5522C29.0833 29.0106 27.9375 30.1981 26.5 30.2398Z"
            stroke="#4A4A4A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.5123 34.5983L26.8053 32.3053C26.8975 32.2098 27.0079 32.1336 27.1299 32.0812C27.2519 32.0288 27.3831 32.0012 27.5159 32C27.6487 31.9989 27.7803 32.0242 27.9032 32.0745C28.0261 32.1247 28.1378 32.199 28.2317 32.2929C28.3256 32.3868 28.3998 32.4984 28.4501 32.6213C28.5004 32.7442 28.5257 32.8759 28.5245 33.0087C28.5234 33.1415 28.4958 33.2727 28.4434 33.3947C28.391 33.5167 28.3148 33.627 28.2193 33.7193L25.2193 36.7193C25.0318 36.9068 24.7775 37.0121 24.5123 37.0121C24.2471 37.0121 23.9928 36.9068 23.8053 36.7193L22.3053 35.2193C22.2098 35.127 22.1336 35.0167 22.0812 34.8947C22.0288 34.7727 22.0012 34.6415 22 34.5087C21.9989 34.3759 22.0242 34.2442 22.0745 34.1213C22.1247 33.9984 22.199 33.8868 22.2929 33.7929C22.3868 33.699 22.4984 33.6247 22.6213 33.5745C22.7442 33.5242 22.8759 33.4989 23.0087 33.5C23.1415 33.5012 23.2727 33.5288 23.3947 33.5812C23.5167 33.6336 23.627 33.7098 23.7193 33.8053L24.5123 34.5983Z"
            fill="#4A4A4A"
          />
        </svg>
      ),
      label: "Personal Character",
      value: personalCount,
    },
    {
      icn: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 54 54"
          fill="none"
        >
          <circle cx="27" cy="27" r="27" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.9825 27.4169C29.8102 26.9293 29.4909 26.5072 29.0687 26.2087C28.6465 25.9102 28.1421 25.7499 27.625 25.75V25.125H26.375V25.75C25.712 25.75 25.0761 26.0134 24.6072 26.4822C24.1384 26.9511 23.875 27.587 23.875 28.25C23.875 28.913 24.1384 29.5489 24.6072 30.0178C25.0761 30.4866 25.712 30.75 26.375 30.75V33.25C25.8312 33.25 25.3681 32.9031 25.1956 32.4169C25.17 32.3373 25.1287 32.2637 25.0742 32.2004C25.0197 32.1371 24.953 32.0853 24.8781 32.0482C24.8032 32.0111 24.7217 31.9894 24.6382 31.9844C24.5548 31.9794 24.4712 31.9911 24.3924 32.019C24.3137 32.0468 24.2412 32.0902 24.1795 32.1465C24.1177 32.2028 24.0679 32.2709 24.033 32.3468C23.998 32.4227 23.9786 32.5049 23.976 32.5884C23.9733 32.6719 23.9874 32.7552 24.0175 32.8331C24.1898 33.3207 24.5091 33.7428 24.9313 34.0413C25.3535 34.3398 25.8579 34.5001 26.375 34.5V35.125H27.625V34.5C28.288 34.5 28.9239 34.2366 29.3928 33.7678C29.8616 33.2989 30.125 32.663 30.125 32C30.125 31.337 29.8616 30.7011 29.3928 30.2322C28.9239 29.7634 28.288 29.5 27.625 29.5V27C27.8835 26.9999 28.1357 27.08 28.3468 27.2292C28.5579 27.3784 28.7175 27.5894 28.8037 27.8331C28.8589 27.9894 28.974 28.1174 29.1235 28.1889C29.1976 28.2243 29.2779 28.2448 29.3599 28.2492C29.4418 28.2535 29.5239 28.2417 29.6012 28.2144C29.6786 28.187 29.7499 28.1447 29.811 28.0899C29.872 28.035 29.9216 27.9686 29.957 27.8946C29.9924 27.8205 30.0129 27.7402 30.0173 27.6583C30.0216 27.5763 30.0098 27.4943 29.9825 27.4169ZM26.375 27C26.0435 27 25.7255 27.1317 25.4911 27.3661C25.2567 27.6005 25.125 27.9185 25.125 28.25C25.125 28.5815 25.2567 28.8995 25.4911 29.1339C25.7255 29.3683 26.0435 29.5 26.375 29.5V27ZM27.625 33.25C27.9565 33.25 28.2745 33.1183 28.5089 32.8839C28.7433 32.6495 28.875 32.3315 28.875 32C28.875 31.6685 28.7433 31.3505 28.5089 31.1161C28.2745 30.8817 27.9565 30.75 27.625 30.75V33.25Z"
            fill="#4A4A4A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2206 17.3881C21.7544 16.6375 24.2706 15.75 27.0244 15.75C29.7206 15.75 32.1706 16.6006 33.6962 17.3412L33.7825 17.3831C34.2425 17.6106 34.6137 17.8256 34.875 18L32.5662 21.375C37.8887 26.8162 42 38.2481 27.0244 38.2481C12.0487 38.2481 16.0494 27.0238 21.4181 21.375L19.1244 18C19.3012 17.8844 19.5256 17.75 19.7931 17.6075C19.9256 17.5363 20.0681 17.4627 20.2206 17.3881ZM31.0825 21.3306L32.9306 18.6287C31.2119 18.7525 29.1669 19.1563 27.1981 19.7263C25.7919 20.1325 24.2294 20.0706 22.7825 19.8038C22.4179 19.7362 22.0558 19.6558 21.6969 19.5625L22.8969 21.3294C25.4687 22.245 28.51 22.245 31.0825 21.3306ZM22.175 22.3937C25.1844 23.5562 28.8012 23.5563 31.8106 22.3925C33.0665 23.717 34.1172 25.2217 34.9281 26.8569C35.7731 28.5806 36.2331 30.3038 36.1581 31.7887C36.0856 33.2219 35.5219 34.4462 34.2344 35.3562C32.8925 36.3044 30.6356 36.9981 27.0237 36.9981C23.4081 36.9981 21.1406 36.3162 19.7862 35.3787C18.4894 34.4806 17.92 33.2725 17.8394 31.8587C17.755 30.39 18.205 28.6756 19.0469 26.94C19.85 25.285 20.9706 23.6919 22.175 22.3937ZM21.4562 18.1975C21.9562 18.3463 22.4787 18.4756 23.0087 18.5737C24.3525 18.8212 25.7 18.8575 26.85 18.5244C28.1901 18.1338 29.5531 17.8268 30.9312 17.605C29.7812 17.2588 28.4362 17 27.0237 17C24.8706 17 22.8625 17.6006 21.4562 18.1975Z"
            fill="#4A4A4A"
          />
        </svg>
      ),
      label: "Token Revenue",
      value: `$ ${tokenRevenue.toFixed(4)}`,

    },
    {
      icn: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 54 54"
          fill="none"
        >
          <circle cx="27" cy="27" r="27" fill="white" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.9825 27.4169C29.8102 26.9293 29.4909 26.5072 29.0687 26.2087C28.6465 25.9102 28.1421 25.7499 27.625 25.75V25.125H26.375V25.75C25.712 25.75 25.0761 26.0134 24.6072 26.4822C24.1384 26.9511 23.875 27.587 23.875 28.25C23.875 28.913 24.1384 29.5489 24.6072 30.0178C25.0761 30.4866 25.712 30.75 26.375 30.75V33.25C25.8312 33.25 25.3681 32.9031 25.1956 32.4169C25.17 32.3373 25.1287 32.2637 25.0742 32.2004C25.0197 32.1371 24.953 32.0853 24.8781 32.0482C24.8032 32.0111 24.7217 31.9894 24.6382 31.9844C24.5548 31.9794 24.4712 31.9911 24.3924 32.019C24.3137 32.0468 24.2412 32.0902 24.1795 32.1465C24.1177 32.2028 24.0679 32.2709 24.033 32.3468C23.998 32.4227 23.9786 32.5049 23.976 32.5884C23.9733 32.6719 23.9874 32.7552 24.0175 32.8331C24.1898 33.3207 24.5091 33.7428 24.9313 34.0413C25.3535 34.3398 25.8579 34.5001 26.375 34.5V35.125H27.625V34.5C28.288 34.5 28.9239 34.2366 29.3928 33.7678C29.8616 33.2989 30.125 32.663 30.125 32C30.125 31.337 29.8616 30.7011 29.3928 30.2322C28.9239 29.7634 28.288 29.5 27.625 29.5V27C27.8835 26.9999 28.1357 27.08 28.3468 27.2292C28.5579 27.3784 28.7175 27.5894 28.8037 27.8331C28.8589 27.9894 28.974 28.1174 29.1235 28.1889C29.1976 28.2243 29.2779 28.2448 29.3599 28.2492C29.4418 28.2535 29.5239 28.2417 29.6012 28.2144C29.6786 28.187 29.7499 28.1447 29.811 28.0899C29.872 28.035 29.9216 27.9686 29.957 27.8946C29.9924 27.8205 30.0129 27.7402 30.0173 27.6583C30.0216 27.5763 30.0098 27.4943 29.9825 27.4169ZM26.375 27C26.0435 27 25.7255 27.1317 25.4911 27.3661C25.2567 27.6005 25.125 27.9185 25.125 28.25C25.125 28.5815 25.2567 28.8995 25.4911 29.1339C25.7255 29.3683 26.0435 29.5 26.375 29.5V27ZM27.625 33.25C27.9565 33.25 28.2745 33.1183 28.5089 32.8839C28.7433 32.6495 28.875 32.3315 28.875 32C28.875 31.6685 28.7433 31.3505 28.5089 31.1161C28.2745 30.8817 27.9565 30.75 27.625 30.75V33.25Z"
            fill="#4A4A4A"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2206 17.3881C21.7544 16.6375 24.2706 15.75 27.0244 15.75C29.7206 15.75 32.1706 16.6006 33.6962 17.3412L33.7825 17.3831C34.2425 17.6106 34.6137 17.8256 34.875 18L32.5662 21.375C37.8887 26.8162 42 38.2481 27.0244 38.2481C12.0487 38.2481 16.0494 27.0238 21.4181 21.375L19.1244 18C19.3012 17.8844 19.5256 17.75 19.7931 17.6075C19.9256 17.5363 20.0681 17.4627 20.2206 17.3881ZM31.0825 21.3306L32.9306 18.6287C31.2119 18.7525 29.1669 19.1563 27.1981 19.7263C25.7919 20.1325 24.2294 20.0706 22.7825 19.8038C22.4179 19.7362 22.0558 19.6558 21.6969 19.5625L22.8969 21.3294C25.4687 22.245 28.51 22.245 31.0825 21.3306ZM22.175 22.3937C25.1844 23.5562 28.8012 23.5563 31.8106 22.3925C33.0665 23.717 34.1172 25.2217 34.9281 26.8569C35.7731 28.5806 36.2331 30.3038 36.1581 31.7887C36.0856 33.2219 35.5219 34.4462 34.2344 35.3562C32.8925 36.3044 30.6356 36.9981 27.0237 36.9981C23.4081 36.9981 21.1406 36.3162 19.7862 35.3787C18.4894 34.4806 17.92 33.2725 17.8394 31.8587C17.755 30.39 18.205 28.6756 19.0469 26.94C19.85 25.285 20.9706 23.6919 22.175 22.3937ZM21.4562 18.1975C21.9562 18.3463 22.4787 18.4756 23.0087 18.5737C24.3525 18.8212 25.7 18.8575 26.85 18.5244C28.1901 18.1338 29.5531 17.8268 30.9312 17.605C29.7812 17.2588 28.4362 17 27.0237 17C24.8706 17 22.8625 17.6006 21.4562 18.1975Z"
            fill="#4A4A4A"
          />
        </svg>
      ),
      label: "Subscription Revenue",
      value: `$ ${subscriptionRevenue.toFixed(4)}`,
    },
  ];
  return (
    <>
      <Row className={`${styles.FeaturedCardWrpper}`}>
        {data &&
          data.length > 0 &&
          data.map((item, key) => (
            <Col key={key} lg="3" md="4" sm="6" className="my-2">
              <div className={`${styles.cardCstm} cardCstm rounded p-2 py-2`}>
                <div className="top d-flex align-items-center gap-10">
                  <div className="icnWrp flex-shrink-0 border-end pe-2 border-secondary">
                    {item.icn}
                  </div>

                  <div className="content text-center">
                    <h6 className="m-0 fw-bold py-1">{item.label}</h6>
                    <h5 className="m-0 fw-sbold py-1">{item.value}</h5>
                  </div>
                </div>
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default FeatureCard;
