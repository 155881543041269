import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import slice from "./slice";
import * as URL from "../../helpers/url_helper";
import { toast } from "react-toastify";

export const getDashboardData = createAsyncThunk(
  "getDashboardData",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await get(URL.GET_DASHBOARD_DATA);
      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error, "error======");
      return Thunk.rejectWithValue(error.response?.data || error);
    }
  }
);

export const getRevenue = createAsyncThunk(
  "getRevenue",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.GET_REVENUE, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getCharacterRevenue = createAsyncThunk(
  "getCharacterRevenue",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.GET_CHARACTER_REVENUE , data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

