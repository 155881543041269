import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, del } from "../../helpers/api_helper";
import slice from "./slice";
import * as URL from "../../helpers/url_helper";
import { toast } from "react-toastify";

export const getFaqCategory = createAsyncThunk(
  "getFaqCategory",
  async ({ data, callback }, Thunk) => {
    try {
      const queryParams = new URLSearchParams(data).toString();
      const url = `${URL.FAQ_CATEGORY_LIST}?${queryParams}`;
      let response = await get(url);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const AddFaqCategory = createAsyncThunk(
  "AddFaqCategory",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await post(URL.FAQ_CATEGORY_ADD, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);
export const editFaqCategory = createAsyncThunk(
  "editFaqCategory",
  async ({ data, callback }, Thunk) => {
    try {
      let response = await put(URL.FAQ_CATEGORY_EDIT + data?._id, data);
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteFaqCategory = createAsyncThunk(
  "deleteFaqCategory",
  async ({ data, callback }, thunkAPI) => {
    console.log(data, "data++=++++++++++++++++++++");
    try {
      const response = await del(URL.FAQ_CATEGORY_DELETE + data);
      console.log(data, "data----------->");
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");

      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCATEGORYWithLanguage = createAsyncThunk(
  "getCATEGORYWithLanguage",
  async ({ data, callback }, Thunk) => {
    try {
      const response = await get(URL.GET_CATEGORY_WITHLANG + data.language);
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      console.log(error, "error");
      return Thunk.rejectWithValue(error);
    }
  }
);
