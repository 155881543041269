import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  APPEARANCELIST: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "APPEARANCELIST",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getAppearnace.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getAppearnace.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      APPEARANCELIST: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.getAppearnace.rejected, (state, action) => ({
      ...state,
      loading: false,
      APPEARANCELIST: [],
      totalCount: 0,
    }));
    builder.addCase(Act.editFaqCategoryPosition.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(
      Act.editFaqCategoryPosition.fulfilled,
      (state, { payload }) => (
        console.log(payload),
        {
          ...state,
          loading: false,
        }
      )
    );
    builder.addCase(Act.editFaqCategoryPosition.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
  },
});

export default slice;
