const inputFields = [
 
    {
      name: "packageSize",
      type: "number",
      label: "Package Size",
      placeholder: "Enter Package size",
    },
    {
        name: "price",
        type: "number",
        label: "Price",
        placeholder: "Enter token price",
      },
      {
        name: "bonusTokens",
        type: "number",
        label: "Bonus Token",
        placeholder: "Enter bonus token",
      },
      // {
      //   name: "status",
      //   type: "select",
      //   label: "Status",
      //   placeholder: "",
      //   options: [
      //     {
      //       value: true,
      //       label: "Active ",
      //     },
      //     {
      //       value: false,
      //       label: "InActive",
      //     },
      //   ],
      // },
 
  ];
  
  export default inputFields;
  