import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../../../store/language/actions";
import { toast } from "react-toastify";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate, useParams } from "react-router-dom";
import {
  editCharacter,
  uploadFile,
  viewCharacter,
} from "../../../../store/actions";
const EditCharacterType = () => {
  const { CHARACTER, loading, totalCount } = useSelector(
    (state) => state.Character
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();
  const [language, setLanguage] = useState([]);
  const navigate = useNavigate();
  const [isAudioLoading, setIsAudioLoading] = useState();
  const [formData, setFormData] = useState({
    type: "",
    name: "",
    image: "",
    gender: "",
    titles: [
      {
        language: "",
        title: "",
      },
    ],
  });
  useEffect(() => {
    if (!CHARACTER?.data) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      id: CHARACTER.data._id,
      type: CHARACTER.data.type || "",
      name: CHARACTER.data.name || "",
      image: CHARACTER.data.image || "",
      audio: CHARACTER?.data?.audio || "",
      gender: CHARACTER.data.gender || "",
      titles: CHARACTER.data.titles?.map((item) => ({
        language: item?.language || "",
        title: item?.title || "",
      })) || [
        {
          language: "",
          title: "",
        },
      ],
    }));
  }, [CHARACTER]);
  const fetchLanguages = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error(err);
        } else {
          setLanguage(res);
        }
      };
      dispatch(getAllLanguage({ callback }));
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error(err);
        } else {
          console.log(res, "fetchData");
        }
      };
      dispatch(viewCharacter({ data: id, callback }));
    } catch (error) {
      console.error("Error fetching Data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const handleTitleTypeChange = (index, field, value) => {
    const updatedTitles = [...formData.titles];
    updatedTitles[index] = {
      ...updatedTitles[index],
      [field]: value,
    };
    setFormData({ ...formData, titles: updatedTitles });
  };
  const addTitleType = () => {
    setFormData({
      ...formData,
      titles: [...formData.titles, { language: "", title: "" }],
    });
  };
  const removeTitleType = (index) => {
    const updatedTitles = formData.titles.filter((_, i) => i !== index);
    setFormData({ ...formData, titles: updatedTitles });
  };

  const handleFiles = (_files) => {
    const files = Array.from(_files).filter((file) => file.size < 10e6);
    if (files.length < _files.length) {
      toast.error("Max file upload size is 10MB");
      return;
    }
    setIsLoading(true);
    const callback = (err, res) => {
      if (err) {
        console.error("File upload error:", err);
        toast.error("Failed to upload file");
      } else {
        console.log(res, " response with  url");
        toast.success("File uploaded successfully");
        setFormData((prev) => ({
          ...prev,
          image: res?.data?.url,
        }));
      }
      setIsLoading(false);
    };
    dispatch(uploadFile({ data: files[0], callback }));
    // setFormData((prev) => ({
    //   ...prev,
    //   image: URL.createObjectURL(files[0]),
    // }));
  };
  const handleAudioFiles = (_files) => {
    const MAX_FILE_SIZE = 50 * 1024 * 1024;
    const files = Array.from(_files).filter(
      (file) => file.size <= MAX_FILE_SIZE
    );
    if (files.length < _files.length) {
      toast.error("Max file upload size is 50MB");
      return;
    }
    setFormData((prev) => ({
      ...prev,
      audio: URL.createObjectURL(files[0]),
    }));
    setIsAudioLoading(true);
    const callback = (err, res) => {
      if (err) {
        console.error("File upload error:", err);
        toast.error("Failed to upload file");
      } else {
        console.log("Response with URL:", res);
        toast.success(res.message);
        setFormData((prev) => ({
          ...prev,
          audio: res?.data?.url,
        }));
      }
      setIsAudioLoading(false);
    };
    dispatch(uploadFile({ data: files[0], callback }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, "forndata =======<>");
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
          console.error("Error submitting:", err);
        } else {
          toast.success("Character Type Added Successfully");
          navigate("/character-setting");
          setFormData({
            type: "",
            name: "",
            gender: "",
            image: "",
            titleType: [
              {
                language: "",
                title: "",
              },
            ],
          });
        }
      };
      dispatch(editCharacter({ data: formData, callback }));
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="addForm position-relative py-3">
        <Container>
          <Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm p-3 rounded bg-white">
                <Form className="mx-auto" onSubmit={handleSubmit}>
                  <div className="py-3">
                    <div className="topHead pb-2">
                      <h5 className="m-0 fw-sbold" style={{ color: "#2E3A59" }}>
                        Edit Character:
                      </h5>
                    </div>
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row className="justify-content-center">
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="ModalType"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Modal Type
                          </label>
                          <select
                            className="form-control"
                            name="type"
                            value={formData.type}
                            onChange={handleChange}
                          >
                            <option value="">Select Modal type</option>
                            <option value="Ethnicity">Ethnicity</option>
                            <option value="Butt">Butt</option>
                            <option value="Breast">Breast</option>
                            <option value="Accent">Accent</option>
                            <option value="BodyType">Body Type</option>
                            <option value="Eye">Eye</option>
                            <option value="HairStyle">Hair Style</option>
                            <option value="HairColor">Hair Color</option>
                            <option value="FaceExpression">
                              Face Expression
                            </option>
                          </select>
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="name"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="Gender"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Gender
                          </label>
                          <select
                            className="form-control"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                          >
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </Col>
                        <Col md="6" className="my-2">
                          <label
                            htmlFor="Image"
                            className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                          >
                            Image
                          </label>
                          {isLoading ? (
                            <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                              <li className="d-flex align-items-center gap-2 position-relative">
                                <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                  <span
                                    className="spinner-border text-primary"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span className="ms-2 text-primary">
                                    Uploading...
                                  </span>
                                </div>
                              </li>
                            </ul>
                          ) : (
                            <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                              {formData?.image &&
                              typeof formData.image === "string" ? (
                                <li className="d-flex align-items-center gap-2 position-relative">
                                  <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                    <img
                                      className="rounded-4"
                                      src={formData.image}
                                      alt="Selected Icon"
                                      width={100}
                                      height={100}
                                    />
                                    <Button
                                      variant="danger"
                                      className="btn-sm border-0 p-0 fw-sbold d-flex align-items-center justify-content-center position-absolute rounded-circle"
                                      style={{
                                        top: 5,
                                        right: 5,
                                        fontSize: 8,
                                        height: 12,
                                        width: 12,
                                      }}
                                      onClick={() =>
                                        setFormData({ ...formData, image: "" })
                                      }
                                    >
                                      X
                                    </Button>
                                  </div>
                                </li>
                              ) : (
                                <input
                                  type="file"
                                  className="form-control"
                                  name="image"
                                  accept=".jpeg,.jpg,.png,.gif,.bmp,.webp"
                                  onChange={(e) => handleFiles(e.target.files)}
                                />
                              )}
                            </ul>
                          )}
                        </Col>
                        {formData.type === "Accent" && (
                          <Col md="6" className="my-2">
                            <label
                              htmlFor="Image"
                              className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                            >
                              Upload Accent Audio
                            </label>
                            {isAudioLoading ? (
                              <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                                <li className="d-flex align-items-center gap-2 position-relative">
                                  <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                    <span
                                      className="spinner-border text-primary"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span className="ms-2 text-primary">
                                      Uploading...
                                    </span>
                                  </div>
                                </li>
                              </ul>
                            ) : (
                              <ul className="list-unstyled pl-0 mb-0 d-flex align-items-center gap-2 flex-wrap">
                                {formData?.audio &&
                                typeof formData.audio === "string" ? (
                                  <li className="d-flex align-items-center gap-2 position-relative">
                                    <div className="d-flex align-items-center bg-light py-2 px-4 rounded-pill position-relative">
                                      <audio controls className="rounded-4">
                                        <source
                                          src={formData.audio}
                                          type="audio/mp3"
                                        />
                                        Your browser does not support the audio
                                        element.
                                      </audio>
                                      <Button
                                        variant="danger"
                                        className="btn-sm border-0 p-0 fw-sbold d-flex align-items-center justify-content-center position-absolute rounded-circle"
                                        style={{
                                          top: 5,
                                          right: 5,
                                          fontSize: 8,
                                          height: 12,
                                          width: 12,
                                        }}
                                        onClick={() =>
                                          setFormData({
                                            ...formData,
                                            audio: "",
                                          })
                                        }
                                      >
                                        X
                                      </Button>
                                    </div>
                                  </li>
                                ) : (
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="image"
                                    accept=".mp3"
                                    onChange={(e) =>
                                      handleAudioFiles(e.target.files)
                                    }
                                  />
                                )}
                              </ul>
                            )}
                          </Col>
                        )}
                        {formData?.titles?.map((item, index) => (
                          <React.Fragment key={index}>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`language-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Select Language
                              </label>
                              <select
                                className="form-control"
                                value={item.language}
                                onChange={(e) =>
                                  handleTitleTypeChange(
                                    index,
                                    "language",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="">Select Language</option>
                                {language.map((lang) => (
                                  <option key={lang._id} value={lang.code}>
                                    {lang.name}
                                  </option>
                                ))}
                              </select>
                            </Col>
                            <Col md="6" className="my-2">
                              <label
                                htmlFor={`title-${index}`}
                                className="form-label text-dark m-0 pb-1 fw-sbold text-uppercase"
                              >
                                Title
                              </label>
                              <div className="d-flex align-items-center">
                                <input
                                  type="text"
                                  className="form-control me-2"
                                  value={item.title}
                                  onChange={(e) =>
                                    handleTitleTypeChange(
                                      index,
                                      "title",
                                      e.target.value
                                    )
                                  }
                                />
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  onClick={() => removeTitleType(index)}
                                  disabled={formData.titles?.length === 1}
                                >
                                  Remove
                                </Button>
                              </div>
                            </Col>
                          </React.Fragment>
                        ))}

                        <div className="d-flex px-4">
                          <Button
                            className="mt-2 btn-sm"
                            onClick={addTitleType}
                            type="button"
                          >
                            Add More
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>

                  <div className="py-3">
                    <div className="mx-auto" style={{ maxWidth: 777 }}>
                      <Row>
                        <Col md="5" className="my-2">
                          <Button
                            className="d-flex align-items-center justify-content-center commonBtn w-100 fw-sbold"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EditCharacterType;
