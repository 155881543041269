import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";
const initialState = {
  loading: false,
  CHARACTERLIST: [],
  totalCount: 0,
};
const slice = createSlice({
  name: "CHARACTERLIST",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.characterList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.characterList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      CHARACTERLIST: payload || [],
      totalCount: payload.totalcount || 0,
    }));
    builder.addCase(Act.characterList.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload || "Unknown error",
      CHARACTERLIST: [],
      totalCount: 0,
    }));
    // character details==
    builder.addCase(Act.getCharacterDetails.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getCharacterDetails.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      CHARACTERLIST: payload || [],
      totalCount: payload.totalcount || 0,
    }));
    builder.addCase(Act.getCharacterDetails.rejected, (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload || "Unknown error",
      CHARACTERLIST: [],
      totalCount: 0,
    }));
    // delete token package
    builder.addCase(Act.deleteCharacterList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Act.deleteCharacterList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Act.deleteCharacterList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });
  },
});

export default slice;
