import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import TableLayout from "../../../../components/TableLayout";
import { RevenueList } from "../../../../store/revenue/actions";
import {
  DownloadPaidRevenueList,
  DownloadRevenueList,
} from "../../../../store/downloadcsv/actions";
const UserRevenue = () => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [tokenData, setTokenData] = useState();
  const [isDownloading, setIsDownloading] = useState();
  const [status, setStatus] = useState();
  const [isPaidDownload, setIsPaidDownload] = useState();
  const { REVENUELIST, loading, totalCount } = useSelector(
    (state) => state.RevenueList
  );
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    status: "all",
    pageNo: currentPage,
    limit: pageSize,
  });
  const fetchData = async () => {
    try {
      const callback = (err, res) => {
        if (err) {
          toast.error(err);
        } else {
          console.log(res);
          setTokenData(res);
          toast.success(res.message);
        }
      };
      dispatch(RevenueList({ data: filter, callback }));
    } catch (error) {
      console.error(error, "<===err");
    }
  };
  const handleFilter = () => {
    setFilter((prev) => ({
      ...prev,
      status: status,
    }));
  };
  const clearFilter = () => {
    setFilter({ pageNo: 1, limit: pageSize, status });
  };
  useEffect(() => {
    const timer = setTimeout(() => fetchData(), 500);
    return () => clearTimeout(timer);
  }, [filter]);
  const handleViewUser = (id) => {
    navigate(`/revenue/${id}`);
  };
  const handlePageChange = (page) => {
    setFilter((prev) => ({ ...prev, pageNo: page.selected + 1 }));
  };
  const handleDownload = () => {
    try {
      setIsDownloading(true);
      const callback = (err, res) => {
        setIsDownloading(false);
        if (err) {
          toast.error(err);
          console.error(err, "Error during download");
        } else {
          console.log(res, "response data");
          let url = res;
          if (url) {
            window.open(url, "_blank");
            toast.success("Download successful!");
          } else {
            toast.error("Download URL not found.");
          }
        }
      };
      dispatch(DownloadRevenueList({ data: { export: "true" }, callback }));
    } catch (error) {
      setIsDownloading(false);
      console.error(error, "<===err");
    }
  };
  const handleDownloadPaid = () => {
    try {
      setIsPaidDownload(true);
      const callback = (err, res) => {
        setIsPaidDownload(false);
        if (err) {
          toast.error(err);
          console.error(err, "Error during download");
        } else {
          console.log(res, "response data");
          let url = res;
          if (url) {
            window.open(url, "_blank");
            toast.success("Download successful!");
          } else {
            toast.error("Download URL not found.");
          }
        }
      };
      dispatch(DownloadPaidRevenueList({ callback }));
    } catch (error) {
      setIsPaidDownload(false);
      console.error(error, "<===err");
    }
  };
  const column = [
    {
      head: "Sr No",
      accessor: "",
      isComponent: true,
      component: (item, ind) => {
        const calculateRowIndex = (ind) =>
          (filter.pageNo - 1) * pageSize + (ind + 1);
        return (
          <span className="text-capitalize cursor-pointer">
            {calculateRowIndex(ind)}
          </span>
        );
      },
    },
    {
      head: "Name",
      accessor: "Name",
    },
    {
      head: "Mobile",
      accessor: "PhoneNumber",
    },
    {
      head: "Email",
      accessor: "Email",
    },
    {
      head: "AccountHolder",
      accessor: "AccountHolderName",
    },
    {
      head: "Address ",
      accessor: "Address",
    },
    {
      head: "SwiftCode",
      accessor: "SwiftCode",
    },
    {
      head: "Iban",
      accessor: "Iban",
    },
    {
      head: "Amount",
      accessor: "Amount",
      isComponent: true,
      component: (item, ind) => (
        <span className="text">
          {typeof item?.Amount === "number"
            ? item?.Amount?.toFixed(4)
            : "0.0000"}
        </span>
      ),
    },
    {
      head: "Wallet",
      accessor: "Wallet",
    },
    {
      head: "Actions",
      accessor: "",
      isComponent: true,
      component: (item) => (
        <div className="ActnBtn d-flex align-items-center  gap-10">
          <Button
            onClick={() => handleViewUser(item._id)}
            className="p-0"
            variant="transparent"
            type="button"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9ZM12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C7 10.6739 7.52678 9.40215 8.46447 8.46447C9.40215 7.52678 10.6739 7 12 7C13.3261 7 14.5979 7.52678 15.5355 8.46447C16.4732 9.40215 17 10.6739 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5Z"
                fill="#6F6F6F"
              />
            </svg>
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <section className="ManageTeacher position-relative">
        <Container>
          <Row className="">
            <Col lg="12" className="my-2  ">
              <div className="d-flex align-items-center justify-content-between ">
                <h5 className="mb-0 py-3 fw-sbold text-capitalize">
                  User Revenue
                </h5>
              </div>
            </Col>
            <Row className="mb-3  d-flex justify-content-between align-items-center">
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={filter.search || ""}
                  onChange={(e) =>
                    setFilter((prev) => ({ ...prev, search: e.target.value }))
                  }
                />
              </Col>
              <Col md={4} className="d-flex gap-3 align-items-center">
                <Form.Select
                  className="py-2"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="pending">Pending</option>
                  <option value="paid">Paid</option>
                </Form.Select>
                <div className="d-flex gap-3">
                  <Button
                    onClick={handleFilter}
                    variant="primary"
                    className="py-2 px-3"
                  >
                    Filter
                  </Button>
                  <Button
                    onClick={clearFilter}
                    variant="secondary"
                    className="py-2 px-3"
                  >
                    Clear
                  </Button>
                </div>
              </Col>
              <Col
                md={4}
                className="text-md-end d-flex gap-2 justify-content-end"
              >
                <Button
                  onClick={handleDownload}
                  variant="info"
                  className="bg-black text-white py-2 px-3"
                  disabled={isDownloading}
                >
                  {isDownloading ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Exporting...
                    </span>
                  ) : (
                    "Export CSV"
                  )}
                </Button>
                <Button
                  onClick={handleDownloadPaid}
                  variant="info"
                  className="bg-black text-white py-2 px-3"
                  disabled={isPaidDownload}
                >
                  {isPaidDownload ? (
                    <span>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Exporting...
                    </span>
                  ) : (
                    " Pay And Export CSV"
                  )}
                </Button>
              </Col>
            </Row>
            <Col lg="12" className="my-2">
              <div className="cardCstm  rounded bg-white">
                {!loading && REVENUELIST?.data?.length > 0 ? (
                  <>
                    <TableLayout
                      data={REVENUELIST?.data}
                      column={column}
                      totalCount={totalCount}
                      limit={filter.limit}
                      handlePageChange={handlePageChange}
                      pageNo={filter.pageNo}
                    />
                  </>
                ) : (
                  <div className="d-flex justify-content-center">
                    <p>No Data Found</p>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default UserRevenue;
