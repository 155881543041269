import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  CATEGORYLIST: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "CATEGORYLIST",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getFaqCategory.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getFaqCategory.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      CATEGORYLIST: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.getFaqCategory.rejected, (state, action) => ({
      ...state,
      loading: false,
      CATEGORYLIST: [],
      totalCount: 0,
    }));
 
    // //add user
    builder.addCase(Act.AddFaqCategory.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(
      Act.AddFaqCategory.fulfilled,
      (state, { payload }) => (
        console.log(payload),
        {
          ...state,
          loading: false,
        }
      )
    );
    builder.addCase(Act.AddFaqCategory.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // edit pages
    builder.addCase(Act.editFaqCategory.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(
      Act.editFaqCategory.fulfilled,
      (state, { payload }) => (
        console.log(payload),
        {
          ...state,
          loading: false,
        }
      )
    );
    builder.addCase(Act.editFaqCategory.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    // delete pages
    builder.addCase(Act.deleteFaqCategory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Act.deleteFaqCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Act.deleteFaqCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || action.error;
    });
    builder.addCase(Act.getCATEGORYWithLanguage.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(
      Act.getCATEGORYWithLanguage.fulfilled,
      (state, { payload }) => ({
        ...state,
        loading: false,
        FAQ: payload.data,
        totalCount: payload.totalcount,
      })
    );
    builder.addCase(Act.getCATEGORYWithLanguage.rejected, (state, action) => ({
      ...state,
      loading: false,
      FAQ: [],
      totalCount: 0,
    }));
  },
});

export default slice;
